import { ThunkAction } from 'redux-thunk';

import * as CatalogClient from 'Clients/Catalog';
import * as ImagesClient from 'Clients/Images';
import { actions as paginatedActions } from 'Store/Search/Paginator';

export const RESETTING_CACHE = 'ADMIN_RESETTING_CACHE';
export const RESET_CACHE_SUCCESS = 'ADMIN_RESET_CACHE_SUCCESS';
export const RESET_CACHE_FAILED = 'ADMIN_RESET_CACHE_FAILED';

export interface AdminAction {
  type: string;
}

function resettingCache(): AdminAction {
  return {
    type: RESETTING_CACHE
  };
}

function resetCacheFailed(): AdminAction {
  return {
    type: RESET_CACHE_FAILED
  };
}

function resetCacheSuccess(): AdminAction {
  return {
    type: RESET_CACHE_SUCCESS
  };
}

export function resetCache(): ThunkAction<Promise<any>, any, any> {
  return (dispatch, getState) => {
    dispatch(resettingCache());

    const promise = Promise.all([
      CatalogClient.Util.resetServerCache(),
      ImagesClient.Util.resetCache()
    ]);

    promise.then(
      c => {
        CatalogClient.Util.clearWebStorage();
        dispatch(paginatedActions.clear());
        dispatch(resetCacheSuccess());
      },
      e => dispatch(resetCacheFailed())
    );

    return promise;
  };
}
