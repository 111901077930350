import _flatten from "lodash-es/flatten";
import _has from "lodash-es/has";
import _map from "lodash-es/map";
import _union from "lodash-es/union";
import _uniq from "lodash-es/uniq";
import _values from "lodash-es/values";

import { ThunkAction } from "redux-thunk";

import * as BasketClient from "Clients/Basket";
import * as CatalogClient from "Clients/Catalog";

import * as Guid from "Utils/Guid";

import RootState from "Store/Root";
import { loadDesign } from "Store/Designs/Actions";
import * as TrykUtils from "Utils/Tryk";
import {
  ColorwayState,
  EmailDestinationOption,
  PhysicalDestinationOption,
  RugState,
  ShippingOptions,
  BasketItemType,
  BasketItemColorway,
  isColorwayItem,
  isRugItem,
  BasketItemRug
} from "Types/Basket";

import {
  getDefaultColorwayProductId,
  getDefaultRugProductId
} from "Utils/Tryk";

import { initializeSwatchOffset, makeOrder } from "./Utils";

import { SurfacePosition, ViewMode } from "Types/Designs";
import { pushAnalytics } from 'Utils/Analytics';

export const LOAD_STORED_BASKET = "basket/LOAD_STORED_BASKET";
export const REMOVE_ITEM = "basket/REMOVE_ITEM";

export const ADD_COLORWAY_ITEM = "basket/ADD_COLORWAY_ITEM";
export const CHANGE_COLORWAY_INSTALL = "basket/CHANGE_COLORWAY_INSTALL";
export const CHANGE_COLORWAY_LAYOUT_BRAND =
  "basket/CHANGE_COLORWAY_LAYOUT_BRAND";
export const CHANGE_COLORWAY_POSITION = "basket/CHANGE_COLORWAY_POSITION";
export const CHANGE_COLORWAY_PRINT_SCALE = "basket/CHANGE_COLORWAY_PRINT_SCALE";
export const CHANGE_COLORWAY_PRODUCT = "basket/CHANGE_COLORWAY_PRODUCT";
export const CHANGE_COLORWAY_ROOM = "basket/CHANGE_COLORWAY_ROOM";

export const ADD_RUG_ITEM = "basket/ADD_RUG_ITEM";
export const CHANGE_RUG_LAYOUT_BRAND = "basket/CHANGE_RUG_LAYOUT_BRAND";
export const CHANGE_RUG_PRINT_SCALE = "basket/CHANGE_RUG_PRINT_SCALE";
export const CHANGE_RUG_PRODUCT = "basket/CHANGE_RUG_PRODUCT";
export const CHANGE_RUG_ROOM = "basket/CHANGE_RUG_ROOM";

export const CHANGE_PROJECT_NAME = "basket/CHANGE_PROJECT_NAME";

export const LOADING_SHIPPING_OPTIONS = "basket/LOADING_SHIPPING_OPTIONS";
export const LOADED_SHIPPING_OPTIONS = "basket/LOADED_SHIPPING_OPTIONS";
export const CLEAR_SHIPPING_OPTIONS = "basket/CLEAR_SHIPPING_OPTIONS";

export const ADD_EMAIL_SHIPPING = "basket/ADD_EMAIL_SHIPPING";
export const ADD_NEW_EMAIL_SHIPPING = "basket/ADD_NEW_EMAIL_SHIPPING";
export const REMOVE_EMAIL_SHIPPING = "basket/REMOVE_EMAIL_SHIPPING";

export const ADD_PHYSICAL_SHIPPING = "basket/ADD_PHYSICAL_SHIPPING";
export const ADD_NEW_PHYSICAL_SHIPPING = "basket/ADD_NEW_PHYSICAL_SHIPPING";
export const REMOVE_PHYSICAL_SHIPPING = "basket/REMOVE_PHYSICAL_SHIPPING";

export const PLACING_ORDER = "basket/PLACING_ORDER";
export const ORDER_PLACED = "basket/ORDER_PLACED";
export const PLACE_ORDER_FAILED = "basket/PLACE_ORDER_FAILED";

export const RESET_BASKET = "basket/RESET";

export interface BasketAction {
  type: string;
  id?: string;
  colorway?: ColorwayState;
  rug?: RugState;
  storage?: BasketClient.BasketStateStorage;
  productId?: number;
  layoutBrandId?: number;
  roomId?: number;
  installId?: number;
  printScale?: number;
  shippingOptions?: ShippingOptions;
  swatchOffset?: TrykApi.Catalog.ITrykSwatchOffset;
  projectName?: string;
  physical?: PhysicalDestinationOption;
  email?: EmailDestinationOption;
}

export function placeOrder(): ThunkAction<Promise<number>, RootState, any> {
  return (dispatch, getState) => {
    dispatch<BasketAction>({
      type: PLACING_ORDER
    });

    const order = makeOrder(getState());
    const request = CatalogClient.User.placeOrder(order);

    request.then(
      x =>
        dispatch<BasketAction>({
          type: ORDER_PLACED
        }),
      err =>
        dispatch<BasketAction>({
          type: PLACE_ORDER_FAILED
        })
    );

    return request;
  };
}

export function resetBasket(): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    BasketClient.reset(state.user.account.data.userId);

    dispatch<BasketAction>({
      type: RESET_BASKET
    });

    return true;
  };
}

export function loadBasketItems(): ThunkAction<any, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();
    const designCodes = _uniq(
      _union(
        _map(state.basket.colorwaysById, x =>
          x.colorway.designCode.toUpperCase()
        ),
        _flatten(
          _map(state.basket.rugsById, x =>
            x.rug.components.map(y => y.designCode.toUpperCase())
          )
        )
      )
    );

    designCodes.forEach(x => dispatch(loadDesign(x)));
  };
}

export function loadStoredBasket(): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const storage = BasketClient.getItemState();

    dispatch({
      type: LOAD_STORED_BASKET,
      storage
    });

    return true;
  };
}

export type AddColorwayOptions = {
  roomId: number;
  installId: number;
  clutColorwayId: string;
  areaRug: TrykApi.Catalog.ITrykAreaRugRequest;

  layoutBrandId?: number;
  productId?: number;
  printScale?: number;
  textureId?: number;
  swatchOffset?: { x: number; y: number };
  viewMode?: ViewMode;
  rotation?: number;
  position?: SurfacePosition;
};

export function addColorwayItem(
  colorway: TrykApi.Catalog.IColorway,
  opts: AddColorwayOptions
): ThunkAction<Promise<boolean>, RootState, any> {
  return (dispatch, getState) => {
    return new Promise<boolean>((resolve, reject) => {
      const layoutBrandId = opts.layoutBrandId || (colorway.brandId === 100 ? 95 : colorway.brandId);
      let productId: number = opts.productId;
      const state = getState();

      if (!productId) {
        const sectionIds = TrykUtils.getSections(state, colorway.designCode);

        productId = getDefaultColorwayProductId(
          state.trykLayouts,
          colorway.brandId,
          opts.roomId > 0,
          opts.viewMode,
          sectionIds
        );
      }

      dispatch<BasketAction>({
        type: ADD_COLORWAY_ITEM,
        colorway: {
          id: Guid.create(),
          colorway,
          layoutBrandId,
          productId,
          roomId: opts.roomId || 0,
          rotation: opts.rotation || 0,
          installId: opts.installId,
          printScale: opts.printScale || 1,
          swatchOffset: initializeSwatchOffset(state, productId, colorway, 1, opts.swatchOffset),
          textureId: opts.textureId || 0,
          clutColorwayId: opts.clutColorwayId,
          areaRug: opts.areaRug,
          position: opts.position
        }
      });

      updateBasketItems(getState());

      resolve(true);
    });
  };
}

export type AddRugOptions = {
  roomId: number;

  layoutBrandId?: number;
  productId?: number;
  printScale?: number;
};

export function addRugItem(
  rug: TrykApi.Catalog.IRug,
  opts: AddRugOptions
): ThunkAction<Promise<boolean>, RootState, any> {
  return (dispatch, getState) => {
    return new Promise<boolean>((resolve, reject) => {
      let layoutBrandId: number = opts.layoutBrandId;
      let productId: number = opts.productId;

      if (!productId) {
        const state = getState();
        productId = getDefaultRugProductId(
          _values(state.trykLayouts.itemsById)
        );
      }

      if (!layoutBrandId) {
        const state = getState();
        layoutBrandId = state.site.item.brands[0].brandId;
      }

      dispatch<BasketAction>({
        type: ADD_RUG_ITEM,
        rug: {
          id: Guid.create(),
          rug,
          layoutBrandId,
          productId,
          roomId: opts.roomId,
          printScale: opts.printScale || 1
        }
      });

      updateBasketItems(getState());

      resolve(true);
    });
  };
}

export function duplicateItem(
  id: string
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (_has(state.basket.colorwaysById, id)) {
      const colorway = state.basket.colorwaysById[id];
      dispatch(
        addColorwayItem(colorway.colorway, {
          layoutBrandId: colorway.layoutBrandId,
          productId: colorway.productId,
          installId: colorway.installId,
          printScale: colorway.printScale,
          roomId: colorway.roomId,
          swatchOffset: colorway.swatchOffset,
          textureId: colorway.textureId,
          clutColorwayId: colorway.clutColorwayId,
          areaRug: colorway.areaRug
        })
      );
    } else if (_has(state.basket.rugsById, id)) {
      const rug = state.basket.rugsById[id];
      dispatch(
        addRugItem(rug.rug, {
          layoutBrandId: rug.layoutBrandId,
          productId: rug.productId,
          printScale: rug.printScale,
          roomId: rug.roomId
        })
      );
    } else {
      throw new Error("Unable to duplicate, unrecognized item type.");
    }

    updateBasketItems(getState());

    return true;
  };
}

export function removeItem(item: BasketItemType): ThunkAction<boolean, RootState, any> {

  // Analytics
  if (isColorwayItem(item)) {
    const basketItem = item as BasketItemColorway;
    pushAnalytics('remove-from-cart', {
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: [{
            id: basketItem.colorway.designCode,
            name: basketItem.colorway.designName,
            brand: basketItem.brand.name,
            category: 'Pattern',
            variant: basketItem.colorway.colorName,
            price: '1.00',
            quantity: 1,
            dimension3: 'undefined',
            dimension4: 'undefined',
            dimension5: 'undefined',
            dimension6: 'undefined',
            metric1: 'undefined',
            metric2: 'undefined',
            metric4: 1
          }]
        }
      }
    });
  } else if (isRugItem(item)) {
    const basketItem = item as BasketItemRug;

    let products = [];
    for (const rugDesignIndex in basketItem.rug.components) {
      const rugDesign = basketItem.rug.components[rugDesignIndex];
      products.push(
        {
          id: rugDesign.designCode,
          name: 'undefined',
          brand: item.brand.name,
          category: 'Pattern',
          variant: 'undefined',
          price: '1.00',
          quantity: 1,
          dimension3: 'undefined',
          dimension4: 'undefined',
          dimension5: 'undefined',
          dimension6: 'undefined',
          metric1: 'undefined',
          metric2: 'undefined',
          metric4: 1
        });
    }
    pushAnalytics('remove-from-cart', {
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: products
        }
      }
    });
  }

  return (dispatch, getState) => {
    dispatch<BasketAction>({
      type: REMOVE_ITEM,
      id: item.id
    });

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemLayoutBrand(
  id: string,
  layoutBrandId: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const { basket } = getState();

    const props = {
      id,
      layoutBrandId
    };

    if (_has(basket.colorwaysById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_COLORWAY_LAYOUT_BRAND,
        ...props
      });
    } else if (_has(basket.rugsById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_RUG_LAYOUT_BRAND,
        ...props
      });
    } else {
      throw `Invalid Basket ID provided: ${id}`;
    }

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemProduct(
  id: string,
  productId: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const { basket } = getState();

    const props = {
      id,
      productId
    };

    if (_has(basket.colorwaysById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_COLORWAY_PRODUCT,
        ...props
      });
    } else if (_has(basket.rugsById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_RUG_PRODUCT,
        ...props
      });
    } else {
      throw `Invalid Basket ID provided: ${id}`;
    }

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemRoom(
  id: string,
  roomId: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const { basket } = getState();

    const props = {
      id,
      roomId
    };

    if (_has(basket.colorwaysById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_COLORWAY_ROOM,
        ...props
      });
    } else if (_has(basket.rugsById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_RUG_ROOM,
        ...props
      });
    } else {
      throw `Invalid Basket ID provided: ${id}`;
    }

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemInstall(
  id: string,
  installId: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    dispatch<BasketAction>({
      type: CHANGE_COLORWAY_INSTALL,
      id,
      installId
    });

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemPosition(
  id: string,
  swatchOffset: TrykApi.Catalog.ITrykSwatchOffset,
  item?: BasketItemType,
  printScale?: number,
  productId?: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const colorwayBasketItem = item && isColorwayItem(item) ? item as BasketItemColorway : null;

    if (colorwayBasketItem && typeof (swatchOffset) !== 'undefined') {
      swatchOffset = initializeSwatchOffset(getState(), productId || colorwayBasketItem.product.productId, colorwayBasketItem.colorway, printScale, swatchOffset);
    }

    dispatch<BasketAction>({
      type: CHANGE_COLORWAY_POSITION,
      id,
      swatchOffset
    });

    updateBasketItems(getState());

    return true;
  };
}

export function changeItemPrintScale(
  id: string,
  printScale: number
): ThunkAction<boolean, RootState, any> {
  return (dispatch, getState) => {
    const { basket } = getState();

    const props = {
      id,
      printScale
    };

    if (_has(basket.colorwaysById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_COLORWAY_PRINT_SCALE,
        ...props
      });
    } else if (_has(basket.rugsById, id)) {
      dispatch<BasketAction>({
        type: CHANGE_RUG_PRINT_SCALE,
        ...props
      });
    } else {
      throw `Invalid Basket ID provided: ${id}`;
    }

    updateBasketItems(getState());

    return true;
  };
}

export function changeProjectName(val: string): BasketAction {
  return {
    type: CHANGE_PROJECT_NAME,
    projectName: val
  };
}

export function clearShippingOptions(): ThunkAction<void, RootState, any> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.user.account.data) {
      BasketClient.clearShippingOptions(state.user.account.data.userId);
    }

    dispatch<BasketAction>({
      type: CLEAR_SHIPPING_OPTIONS
    });
  };
}

export function loadShippingOptions(): ThunkAction<void, RootState, any> {
  return (dispatch, getState) => {
    dispatch<BasketAction>({
      type: LOADING_SHIPPING_OPTIONS
    });

    const state = getState();
    const savedOptions = BasketClient.getShippingOptions(
      state.user.account.data.userId
    );

    if (savedOptions) {
      dispatch<BasketAction>({
        type: LOADED_SHIPPING_OPTIONS,
        shippingOptions: savedOptions
      });
    } else {
      CatalogClient.User.getShippingHistory().then(
        res => {
          dispatch<BasketAction>({
            type: LOADED_SHIPPING_OPTIONS,
            shippingOptions: {
              emailDestinations: res.emailDestinations.map(email => {
                return {
                  ...email,
                  id: Guid.create()
                };
              }),
              physicalDestinations: res.physicalDestinations.map(phys => {
                return {
                  ...phys,
                  id: Guid.create()
                };
              })
            }
          });

          updateBasketShipping(getState());
        },
        err => {
          dispatch<BasketAction>({
            type: LOADED_SHIPPING_OPTIONS,
            shippingOptions: {
              emailDestinations: [],
              physicalDestinations: []
            }
          });

          updateBasketShipping(getState());
        }
      );
    }
  };
}

export function addEmailShipping(id: string): BasketAction {
  return {
    type: ADD_EMAIL_SHIPPING,
    id
  };
}

export function addNewEmailShipping(
  email: EmailDestinationOption
): BasketAction {
  return {
    type: ADD_NEW_EMAIL_SHIPPING,
    email
  };
}

export function removeEmailShipping(id: string): BasketAction {
  return {
    type: REMOVE_EMAIL_SHIPPING,
    id
  };
}

export function addPhysicalShipping(id: string): BasketAction {
  return {
    type: ADD_PHYSICAL_SHIPPING,
    id
  };
}

export function addNewPhysicalShipping(
  physical: PhysicalDestinationOption
): BasketAction {
  return {
    type: ADD_NEW_PHYSICAL_SHIPPING,
    physical
  };
}

export function removePhysicalShipping(id: string): BasketAction {
  return {
    type: REMOVE_PHYSICAL_SHIPPING,
    id
  };
}

function updateBasketItems(state: RootState) {
  BasketClient.saveItemState(state.basket);
}

function updateBasketShipping(state: RootState) {
  BasketClient.saveShippingOptions(state.user.account.data.userId, {
    emailDestinations: _values(state.basket.emailsById),
    physicalDestinations: _values(state.basket.physicalsById)
  });
}
